import styled from 'styled-components';
import type { FontSizes } from 'styled-components';

import { th } from '@yoweb/ui/styles/utils/theme';
import { textBase } from './textBase';
import { createSizeMixin, type WithSize } from '@yoweb/ui/styles/utils/mixins';
import type { TextBaseProps } from './textBase';

export type InteractiveMarketingSize = 'sm' | 'md' | 'lg' | 'xl';
type InteractiveMarketingProps = TextBaseProps & WithSize<InteractiveMarketingSize>;

/** Map interactive size to theme key. */
const sizeMapping: Record<InteractiveMarketingSize, keyof FontSizes> = {
  sm: 'marketingInteractiveSm',
  md: 'marketingInteractiveMd',
  lg: 'marketingInteractiveLg',
  xl: 'marketingInteractiveXl',
};

/**
 * font-size + line-height + letter-spacing mixin.
 * Control all with a single prop.
 */
const interactiveMarketingSizeMixin = createSizeMixin<
  InteractiveMarketingSize,
  InteractiveMarketingProps
>(
  ({ size = 'sm' as InteractiveMarketingSize }, tagFn) =>
    tagFn`
      font-size: ${th.getFontSize(sizeMapping[size])};
      line-height: ${th.getLineHeight(sizeMapping[size])};
       letter-spacing: ${th.getLetterSpacing(sizeMapping[size])};
    `,
);

/**
 * Interactive text component with multiple props to modify.
 */
export const InteractiveMarketing = styled.span`
  ${textBase};
  ${interactiveMarketingSizeMixin};
`;

export { interactiveMarketingSizeMixin };
