import { Container } from '@yoweb/ui/components/containers/Container';
import { Text, type TextProps } from '@yoweb/ui/components/typography';

type SmallPrintProps = {
  text: string | ReactNode;
  align?: TextProps['textAlign'];
  noContainer?: boolean;
};

export const SmallPrint = ({ text, align = 'center', noContainer = false }: SmallPrintProps) => {
  const smallText = (
    <Text size="sm" variant="muted2" textAlign={align} data-testid="small-print">
      {text}
    </Text>
  );

  if (noContainer) {
    return smallText;
  }

  return <Container>{smallText}</Container>;
};
