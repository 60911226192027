import type { RichTextField } from '@prismicio/types';

import type {
  ArrayToUnion,
  CreateRequiredFields,
  PrismicSlice,
} from '@yoweb/prismic-slicemachine/slices/slicesTypes';

export const SmallPrintSliceType = 'small_print_slice';

// Required fields in slice
export const requiredPrimaryFields = ['text'] as const; // Array of all required primary fields.
export const requiredItemsFields = [] as const; // Array of all required items fields.

type Primary = {
  text: RichTextField;
};

export type SmallPrintSliceResponse = PrismicSlice<
  typeof SmallPrintSliceType,
  CreateRequiredFields<ArrayToUnion<typeof requiredPrimaryFields>, Primary>
>;
