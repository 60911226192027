import styled from 'styled-components';
import Image from 'next/legacy/image';
import Link from 'next/link';

import { media, pxToRem } from '@yoweb/ui/styles/utils';
import { getRadii, getSpace, getColor, getLetterSpacing } from '@yoweb/ui/styles/utils/theme';
import { Box } from '@yoweb/ui/components/Box';
import { Flex } from '@yoweb/ui/components/Flex';
import { Container } from '@yoweb/ui/components/containers/Container';
import { Text, Title } from '@yoweb/ui/components/typography';
import { Button, ButtonLink } from '@yoweb/ui/components/buttons';
import { GridItem } from '@yoweb/ui/components/Grid/MarketingGrid';
import AnimateIn, { InView, DURATIONS, StaggerElements } from '@yoweb/ui/components/AnimateIn';
import { CircleCheckIcon } from '@yoweb/ui/components/Icon/CircleCheckIcon';
import { Trans } from '@yoweb/next-i18next';

export type ContentImageSectionProps = {
  title: string;
  image: string;
  itemDecoration: string;
  imageOnLeft: boolean;
  items: { text: string }[];
  subtitle?: string;
  imageAltText?: string;
  imageLabel?: ReactNode;
  ctaLabel?: string;
  ctaHref?: string;
  ctaOnClick?: () => void;
  disclaimer?: string;
  disclaimerOnClick?: () => void;
  navId?: string;
};

const IDS = {
  container: 'content-image-section-container',
  title: 'content-image-section-title',
  subtitle: 'content-image-section-subtitle',
  image: 'content-image-section-image',
  checkmark: 'checkmark-list',
  bulletpoint: 'bulletpoint-list',
  ctaLink: 'content-image-section-cta-link',
  ctaButton: 'content-image-section-cta-button',
};

export const ContentImageSection = ({
  title,
  subtitle,
  items,
  image,
  imageAltText,
  imageLabel,
  ctaLabel,
  ctaHref,
  ctaOnClick,
  itemDecoration,
  disclaimer,
  disclaimerOnClick,
  imageOnLeft,
  navId,
}: ContentImageSectionProps) => {
  const getCheckmarkList = (items: { text: string }[]) => (
    <Flex as="ul" flexDirection="column" data-testid={IDS.checkmark}>
      {items.map(({ text }, i) => (
        <Box as="li" key={`bl-assistant-${i}`} my={{ _: 'small3', md: 'small2' }}>
          <Flex display="inline-flex" flexWrap="wrap" gap="small3">
            <CircleCheckIcon width={24} height={24} />
            <Text weight="bold">{text}</Text>
          </Flex>
        </Box>
      ))}
    </Flex>
  );

  const getBulletpointList = (items: { text: string }[]) => (
    <Box mt="normal1" mb="normal3" maxWidth={450}>
      <ULDisc as="ul" flexDirection="column" data-testid={IDS.bulletpoint}>
        {items.map(({ text }, i) => (
          <Box as="li" key={`bl-membership-${i}`} my="small3">
            <ItemText
              as="p"
              size={{ _: 'xs', md: 'sm' }}
              lineHeight="titleSm"
              weight="regular"
              variant="primary"
            >
              {text}
            </ItemText>
          </Box>
        ))}
      </ULDisc>
    </Box>
  );

  const getDecoratedList = (items: { text: string }[]) => {
    switch (itemDecoration) {
      case 'checkmark':
        return getCheckmarkList(items);
      case 'bulletpoint':
        return getBulletpointList(items);
      default:
        return null;
    }
  };

  const imagePosition = imageOnLeft ? '1 / 7' : '7 / 13';
  const textPosition = imageOnLeft ? '8 / 13' : '1 / 7';

  return (
    <ContentImageSectionContainer
      id={navId}
      data-testid={IDS.container}
      backgroundColor={{ xs: 'sand', sm: 'sand', md: 'base000' }}
      withGrid
    >
      <GridItem
        gridColumn={{ _: '1 / 5', md: '1 / 9', lg: textPosition }}
        gridRow={{ _: '2 / 3', lg: '1' }}
        pb={{ _: 'normal3', md: 'medium3' }}
        pt={{ _: 'none', lg: 'medium3' }}
      >
        <InView>
          {(isInView) => (
            <StaggerElements isInView={isInView}>
              <Title size="lg" data-testid={IDS.title}>
                {title}
              </Title>
              <Box my="normal2">
                <Text
                  weight="regular"
                  variant="muted"
                  size={{ _: 'lg', md: 'xl' }}
                  data-testid={IDS.subtitle}
                >
                  {subtitle}
                </Text>
              </Box>
              {getDecoratedList(items)}
              {ctaHref && ctaLabel && (
                <ButtonWrapper>
                  <Link href={ctaHref} passHref legacyBehavior>
                    <Button data-testid={IDS.ctaLink} as="a" size="lg">
                      {ctaLabel}
                    </Button>
                  </Link>
                </ButtonWrapper>
              )}
              {ctaOnClick && ctaLabel && (
                <Button data-testid={IDS.ctaButton} onClick={ctaOnClick}>
                  {ctaLabel}
                </Button>
              )}
              {disclaimer && (
                <Box mt="normal2">
                  <Text size="sm" variant="muted">
                    {disclaimer}
                    {disclaimerOnClick && (
                      <Trans
                        components={[
                          <ButtonLink
                            key="0"
                            onClick={disclaimerOnClick}
                            size="sm"
                            weight="regular"
                            variant="muted"
                          />,
                        ]}
                      />
                    )}
                  </Text>
                </Box>
              )}
            </StaggerElements>
          )}
        </InView>
      </GridItem>
      <GridItem
        gridColumn={{ _: '1 / 5', md: '1 / 9', lg: imagePosition }}
        gridRow={{ _: '1 / 2', lg: '1' }}
        mr={{ lg: -24 }}
        pb={{ _: 'medium2', lg: 'medium3' }}
        pt={{ _: 'none', lg: 'medium3' }}
      >
        <InView>
          {(isInView) => (
            <AnimateIn
              isVisible={isInView}
              config={{
                opacity: { animate: true, duration: DURATIONS.d67 },
                y: { animate: true, offset: 30, duration: DURATIONS.d33 },
              }}
            >
              <Box mx={{ _: -24, lg: 'none' }}>
                <ImageWithLabel data-testid={IDS.image}>
                  <Box
                    width="100%"
                    height="100%"
                    maxHeight={{ _: 624 / 1.5, lg: 624 }}
                    maxWidth={{ _: 624 / 1.5, lg: 624 }}
                    position="relative"
                  >
                    <RoundedImage
                      alt={imageAltText}
                      height={624}
                      layout={'responsive'}
                      objectFit="cover"
                      src={image}
                      width={624}
                    />
                    {imageLabel && (
                      <StyledLabel size={{ _: 'sm', md: 'lg', lg: 'xl' }}>{imageLabel}</StyledLabel>
                    )}
                  </Box>
                </ImageWithLabel>
              </Box>
            </AnimateIn>
          )}
        </InView>
      </GridItem>
    </ContentImageSectionContainer>
  );
};

const ContentImageSectionContainer = styled(Container)`
  align-items: center;
`;

const RoundedImage = styled(Image)`
  && {
    ${media.md`
      border-radius: ${getRadii('large')};
    `}
  }
`;

const ULDisc = styled(Flex)`
  list-style: disc;
  margin-left: ${getSpace('normal2')};
`;

const ImageWithLabel = styled.div`
  display: grid;
  justify-items: center;

  ${media.md`
    height: auto;
  `}

  ${media.lg`
    border-radius: ${getRadii('large')};
    overflow: hidden;
  `}
`;

const StyledLabel = styled(Text)`
  && {
    bottom: ${getSpace('normal1')};
    color: ${getColor('base000')};
    position: absolute;
    right: ${getSpace('normal2')};
    z-index: 2;
    ${media.lg`
      bottom: ${getSpace('normal3')};
      right: ${getSpace('normal4')};
  `}
  }
`;

const ButtonWrapper = styled.div`
  margin-top: ${getSpace('small4')};
  grid-column: 1/3;
  width: fit-content;

  ${media.md`
    grid-column: 1/4;
    width: fit-content;
  `}

  ${media.lg`
    margin-top: ${getSpace('normal4')};
    grid-column: 1/7;
  `}
`;

const ItemText = styled(Title)`
  letter-spacing: ${getLetterSpacing('titleMd')};
  line-height: ${pxToRem(24)};

  ${media.md`
    line-height: ${pxToRem(40)};
  `}
`;
