import { PrismicRichText } from '@prismicio/react';
import * as prismicHelpers from '@prismicio/helpers';

import {
  type ContentImageSectionSliceResponse,
  requiredPrimaryFields,
  requiredItemsFields,
} from './ContentImageSectionSliceTypes';
import { ContentImageSection } from '@yoweb/ui/components/sections/ContentImageSection/ContentImageSection';
import { inverseLabelSerializer } from '@yoweb/prismic-slicemachine/utils/serializers';
import { withPrismicGuardian } from '@yoweb/prismic-slicemachine/components/withPrismicGuardian';

type ContentImageSectionSliceProps = {
  slice: ContentImageSectionSliceResponse;
  hideCta?: boolean;
};

const ContentImageSectionSlice = ({ slice, hideCta, ...rest }: ContentImageSectionSliceProps) => {
  const {
    title,
    image,
    imageOnLeft,
    itemDecoration,
    subtitle,
    ctaLabel,
    ctaHref,
    imageLabel,
    disclaimer,
    navId,
  } = slice.primary;

  const props = {
    title: prismicHelpers.asText(title),
    image: image.url ?? '',
    imageOnLeft,
    itemDecoration,
    subtitle: subtitle ? prismicHelpers.asText(subtitle) : undefined,
    ctaLabel: hideCta ? undefined : ctaLabel && prismicHelpers.asText(ctaLabel),
    ctaHref: hideCta ? undefined : ctaHref && prismicHelpers.asText(ctaHref),
    disclaimer: disclaimer ? prismicHelpers.asText(disclaimer) : undefined,
    imageLabel: imageLabel ? (
      <PrismicRichText field={imageLabel} components={inverseLabelSerializer} />
    ) : undefined,
    items: slice.items.map(({ itemText }) => ({
      text: prismicHelpers.asText(itemText),
    })),
    navId,
  };

  return <ContentImageSection {...props} {...rest} />;
};

export default withPrismicGuardian(ContentImageSectionSlice, {
  primaryRequired: requiredPrimaryFields,
  itemsRequired: requiredItemsFields,
});
