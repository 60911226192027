import type { ImageField } from '@prismicio/types';

import type {
  ArrayToUnion,
  CreateRequiredFields,
  PrismicSlice,
} from '@yoweb/prismic-slicemachine/slices/slicesTypes';

export const CardCarouselSectionSliceType = 'card_carousel_section_slice';

// Required fields in slice
export const requiredPrimaryFields = ['title'] as const; // Array of all required primary fields.
export const requiredItemsFields = ['cardId', 'title', 'image'] as const; // Array of all required items fields.

type Primary = {
  title: string;
  subtitle?: string;
  backgroundColor?: string;
};

type Item = {
  cardId: number;
  title: string;
  description: string;
  label: string;
  image: ImageField;
};

type Default = PrismicSlice<
  typeof CardCarouselSectionSliceType,
  CreateRequiredFields<ArrayToUnion<typeof requiredPrimaryFields>, Primary>,
  CreateRequiredFields<ArrayToUnion<typeof requiredItemsFields>, Item>
>;

type ImageWithTitleOnly = PrismicSlice<
  typeof CardCarouselSectionSliceType,
  CreateRequiredFields<ArrayToUnion<typeof requiredPrimaryFields>, Primary>,
  CreateRequiredFields<ArrayToUnion<typeof requiredItemsFields>, Item>,
  'imageWithTitleOnly'
>;

export type CardCarouselSectionSliceResponse = Default | ImageWithTitleOnly;
