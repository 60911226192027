import type { RichTextField, ImageField } from '@prismicio/types';

import type {
  ArrayToUnion,
  CreateRequiredFields,
  PrismicSlice,
} from '@yoweb/prismic-slicemachine/slices/slicesTypes';

export const ContentImageSectionSliceType = 'content_image_section_slice';

export const requiredPrimaryFields = ['title', 'image', 'imageOnLeft', 'itemDecoration'] as const; // Array of all required primary fields.
export const requiredItemsFields = ['itemText'] as const; // Array of all required items fields.

type Primary = {
  title: RichTextField;
  image: ImageField;
  imageOnLeft: boolean;
  itemDecoration: string;
  subtitle?: RichTextField;
  ctaLabel?: RichTextField;
  ctaHref?: RichTextField;
  imageLabel?: RichTextField;
  disclaimer?: RichTextField;
  navId?: string;
};

type Items = {
  itemText: RichTextField;
};

export type ContentImageSectionSliceResponse = PrismicSlice<
  typeof ContentImageSectionSliceType,
  CreateRequiredFields<ArrayToUnion<typeof requiredPrimaryFields>, Primary>,
  CreateRequiredFields<ArrayToUnion<typeof requiredItemsFields>, Items>
>;
