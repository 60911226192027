import { type Colors } from 'styled-components';

import { CardCarouselSection } from '@yoweb/ui/components/marketing/CardCarouselSection';
import type { CardCarouselSectionProps } from '@yoweb/ui/components/marketing/CardCarouselSection';
import {
  type CardCarouselSectionSliceResponse,
  requiredPrimaryFields,
  requiredItemsFields,
} from './CardCarouselSectionSliceTypes';
import { withPrismicGuardian } from '@yoweb/prismic-slicemachine/components/withPrismicGuardian';

type CardCarouselSectionSliceProps = {
  slice: CardCarouselSectionSliceResponse;
} & Omit<CardCarouselSectionProps, 'title' | 'subtitle'>;

const CardCarouselSectionSlice = ({ slice, children, ...rest }: CardCarouselSectionSliceProps) => {
  const { title, subtitle, backgroundColor = 'sand' } = slice.primary;
  const props = {
    title,
    subtitle,
    variant: slice.variation,
  };

  return (
    <CardCarouselSection background={backgroundColor as keyof Colors} {...props} {...rest}>
      {children}
    </CardCarouselSection>
  );
};

export default withPrismicGuardian(CardCarouselSectionSlice, {
  type: 'variations',
  variations: {
    'default-slice': {
      primaryRequired: requiredPrimaryFields,
      itemsRequired: requiredItemsFields,
    },
    imageWithTitleOnly: {
      primaryRequired: requiredPrimaryFields,
      itemsRequired: requiredItemsFields,
    },
  },
});
