import type { ReactNode } from 'react';
import styled, { type Colors, css } from 'styled-components';

import { Title } from '@yoweb/ui/components/typography';
import { getColor, getSpace } from '@yoweb/ui/styles/utils/theme';
import { media, pxToRem } from '@yoweb/ui/styles/utils';
import { CardCarousel } from '../CardCarousel';
import { StaggerElements, InView } from '@yoweb/ui/components/AnimateIn';

export type Variation = 'default' | 'default-slice' | 'imageWithTitleOnly';

export type CardCarouselSectionProps = {
  animatedIn?: boolean;
  background?: keyof Colors;
  children: ReactNode[];
  className?: string;
  id: string;
  subtitle?: string;
  title: string;
  variant?: Variation;
} & DataTestId;

/**
 * Section with cards in a carousel
 */
export const CardCarouselSection = ({
  animatedIn,
  background,
  children,
  className,
  id,
  subtitle,
  title,
  variant,
  'data-testid': dataTestId = 'card-carousel-section',
}: CardCarouselSectionProps) => {
  let cardGap = 24;
  let itemWidth;
  if (variant === 'imageWithTitleOnly') {
    cardGap = 8;
    itemWidth = 248;
  }
  return (
    <Container data-testid={dataTestId} background={background} className={className}>
      <InView>
        {(isInView) => (
          <Header>
            <StaggerElements isInView={isInView}>
              <StyledTitle data-testid={`${dataTestId}-title`} size={{ _: 'md', lg: 'xl' }}>
                {title}
              </StyledTitle>
              {subtitle && (
                <Title
                  data-testid={`${dataTestId}-text`}
                  as="h3"
                  size={{ _: 'xs', lg: 'sm' }}
                  weight="regular"
                >
                  {subtitle}
                </Title>
              )}
            </StaggerElements>
          </Header>
        )}
      </InView>
      <CardCarousel
        cardGap={cardGap}
        itemWidth={itemWidth}
        dataTestId={`${variant}-card-carousel`}
        id={id}
        animatedIn={animatedIn}
      >
        {children}
      </CardCarousel>
    </Container>
  );
};

const Container = styled.section<{ background?: keyof Colors }>`
  ${({ background }) =>
    background &&
    css`
      background: ${getColor(background)};
    `};

  padding: ${getSpace('large1')} 0 ${getSpace('normal2')};
  overflow: hidden;

  ${media.md`
    padding-bottom: ${getSpace('normal3')};
  `}

  ${media.lg`
    padding-bottom: ${getSpace('large2')};
  `}
`;

const StyledTitle = styled(Title)`
  width: width;

  && {
    padding-bottom: ${pxToRem(12)};
  }
`;

const Header = styled.div`
  display: grid;
  margin-bottom: ${getSpace('medium1')};
  margin-left: auto;
  margin-right: auto;
  max-width: ${pxToRem(480)};
  padding: 0 ${getSpace('normal2')};
  row-gap: ${getSpace('small2')};
  text-align: center;

  ${media.lg`
    max-width: ${pxToRem(560)};
    row-gap: ${pxToRem(20)};
  `}
`;
