import { PrismicRichText } from '@prismicio/react';

import { withPrismicGuardian } from '@yoweb/prismic-slicemachine/components/withPrismicGuardian';
import {
  type SmallPrintSliceResponse,
  requiredPrimaryFields,
  requiredItemsFields,
} from './SmallPrintSliceTypes';
import { SmallPrint } from '@yoweb/ui/components/marketing/SmallPrint';
import { smallPrintSerializer } from '@yoweb/prismic-slicemachine/utils/serializers';

type SmallPrintSliceProps = {
  slice: SmallPrintSliceResponse;
};

const SmallPrintSlice = ({ slice, ...rest }: SmallPrintSliceProps) => (
  <SmallPrint
    text={<PrismicRichText field={slice.primary.text} components={smallPrintSerializer} />}
    {...rest}
  />
);

export default withPrismicGuardian(SmallPrintSlice, {
  primaryRequired: requiredPrimaryFields,
  itemsRequired: requiredItemsFields,
});
